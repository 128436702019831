/*jshint esversion: 6 */
import Ajax from '../../common/Ajax';
import foreachPolyfill from "../../common/polyfill-foreach";

export default (function(config){

    let categoryMains = document.getElementsByClassName('parent_category');
    let categorySubs = document.getElementsByClassName('child_category');

    for(let i = 0; i < categoryMains.length; i++) {

        let categoryMain = categoryMains[i];
        let categorySub = categorySubs[i];

        categoryMain.onchange = function(){

            let option = this.options[this.selectedIndex];

            let route = '/';
            if(config.env === 'dev'){
                //route = '/app_dev.php/';
            }

            route = route + config.locale + '/widget/search-categories/home/' + option.value;

            categorySub.innerHTML = `<option value="">${translations.loading} ... </option>`;
            categorySub.disabled = true;

            Ajax.getRequest(route, function(response){


                let sortStringObject = function sortStringObject(obj){
                    let arr = [];
                    for (let prop in obj) {
                        if (obj.hasOwnProperty(prop)) {
                            arr.push({
                                'key': prop,
                                'value': obj[prop]
                            });
                        }
                    }
                    arr.sort(function(a, b) {

                        return  a.value.toLowerCase().localeCompare(b.value.toLowerCase());
                    });

                    return arr;
                };

                let html ='';
                let sorted = sortStringObject(response);
                console.log(`config.locale: ${config.locale}`);
                sorted.push({key: 0, value: translations.select_all});
                sorted.forEach(item => {
                    html = html + "<option value='" + item.key + "'>" + item.value + "</option>";
                });

                categorySub.innerHTML = html;
                categorySub.disabled = false;
            });

        };

    }


});


