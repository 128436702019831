/*jshint esversion: 6 */

/*
   Purpose: Use a jQuery UI dialog to control a modal (as defined in a separate template).
   On smaller (mobile) screens shows the modal full width.
   Works when orientation of mobile changes by resetting the width.
   Typically used for the Christmas CEO messages.
 */
let WidgetModal = function(config){

    // Turn off the local storage mechanism by not setting this attribute on the modal.
    config.localStorageKey = $(config.selector).attr('data-local-storage-key');

    let dialogSettings = {
        autoOpen: false,
        width: Math.min($(window).width(), config.maxWidth),
        draggable: false,
        resizable: false,
        modal: true,
        dialogClass: config.dialogClass,
        position: {
            my: "center top",
            at: "center top",
            of: "body"
        },
        title: "",
        show: {
            effect: "blind",
            duration: config.animationDuration
        },
        hide: {
            effect: "blind",
            duration: config.animationDuration
        },
        open: function(){
            $('.ui-widget-overlay').bind('click',function(){
                $(config.selector).dialog('close');
            })
        },
        close: function() {
            if (typeof config.localStorageKey !== 'undefined') {
                localStorage[config.localStorageKey] = "1";
            }
            $('#xmas-video-iframe').remove();
        },
        closeOnEscape: true,
    };

    // Fix problem where the close button icon X does not appear due to loading jQuery after Bootstrap javascripts.
    // https://stackoverflow.com/questions/17367736/jquery-ui-dialog-missing-close-icon
    if (typeof $.fn.bootstrapBtn =='undefined') {
        $.fn.bootstrapBtn = $.fn.button.noConflict();
    }

    $(config.selector).dialog(dialogSettings);

    if (typeof config.buttonSelector !== 'undefined') {
        $(config.buttonSelector).click(function(){
            $(config.selector).dialog("open");
        });
    }

    let open = function() {

        if (typeof config.localStorageKey !== 'undefined') {
            let stateString = localStorage[config.localStorageKey];
            if(stateString !== undefined){
                return;
            }
        }

        $(config.selector).dialog("open");
    };

    // Mobile devices change width depending on orientation and this will resize the width accordingly.
    // It is also helpful for testing e.g. in responsive chrome dev tools the dialog will stay full width.
    $(window).resize(function () {
        if($(window).width() <= config.maxWidth) {
            $(config.selector).dialog('option', 'width', $(window).width());
        }
    });

    return {
        open: open
    };

};

export default WidgetModal;


