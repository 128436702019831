/*jshint esversion: 6 */

import WidgetModal from '../widgets/WidgetModal'

window.addEventListener("load",(event) => {

    const d = new Date();
    let year = d.getFullYear();
    let modalSelector = '#xmas-message-modal';

    let xmasMessage = new WidgetModal({
        selector: modalSelector,
        dialogClass: 'xmas-message',
        maxWidth: 992, // bootstrap5 lg size (although this is a jQuery dialog)
        animationDuration: 500
    });

    xmasMessage.open();

    // Count donate button clicks in Google Analytics
    $(modalSelector + ' .donate.button').click(function() {
        if (typeof dataLayer != 'undefined') {
            let dataObject = {
                'event': `donate xmas${year} button pressed`,
                'category': 'donations',
                'label': `donate xmas${year} form submitted`
            };
            console.log('Saving this data to Google analytics:');
            console.log(dataObject);
            dataLayer.push(dataObject);
        } else {
            console.log('Warning: dataLayer undefined');
        }
    });

});
